* {
	margin: 0;
}

html {
	color: var(--joy-palette-grey-90);
	font-family: "Public Sans", sans-serif;
	font-variant-numeric: tabular-nums;
	line-height: 1.5;
	scroll-behavior: smooth;
	tab-size: 4;
	text-size-adjust: 100%;
}

body {
	line-height: inherit;
}

main.app {
	display: flex;
	max-height: 100vh;
	min-height: 100vh;

	/* FIXME: remove max-height for smaller views (e.g. mobile) */
}

h1,
h2,
h3,
h4,
h5 {
	text-wrap: balance;
}

p {
	white-space: pre;
}

*,
::before,
::after {
	border-color: currentcolor;
	border-style: solid;
	border-width: 0;
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: inherit;
}

svg {
	display: block;
	shape-rendering: auto;
	text-rendering: optimizelegibility;
}

pre {
	background-color: rgb(55 65 81 / 100%);
	border-radius: 0.25rem;
	color: rgb(229 231 235 / 100%);
	overflow: scroll;
	padding: 0.5rem 0.75rem;
	white-space: break-spaces;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/** We need this for tabular-nums to work in number inputs */
input[type="number"] {
	font-variant-numeric: tabular-nums;
}

/*  --------------------------------------------------------------------------------
--- VAR
-------------------------------------------------------------------------------- */
:root {
	/* Z-indexes */
	--z-index-toast: 700;
	--z-index-cover: 600;

	/** Toastify specifics
	 *  The 'important' is needed to have them work inside Storybook.
	 *  Note that the joy variables are NOT linted at the moment.
	 */
	--toastify-color-progress-success: var(
		--joy-palette-transactional-success
	) !important;
	--toastify-color-progress-error: var(
		--joy-palette-transactional-danger
	) !important;
	--toastify-color-progress-info: var(--joy-palette-blue-primary) !important;
	--toastify-color-progress-warning: var(
		--joy-palette-transactional-warning
	) !important;
}
